.head-shot {
  float: left;
  margin: 0 1rem 1rem 0;
}
.example {
  float: right;
  margin: 0 0 1rem 1rem;
}
.pull-right {
  height: 250px;
  border: 2px solid #ccc;
  border-radius: 1rem;
}
