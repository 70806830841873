.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  background-color: var(--primary-main);
}
.logoLink {
  display: block;
  width: 193px;
  height: 36px;
  margin-inline-start: 0.5em;
}
.logo {
  width: 100%;
  height: auto;
}
