.canvas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  border-style: dashed;
  border-width: 1px;
  border-color: var(--gray-light);
}
.canvas:last-child {
  margin-right: 0 !important;
}
.size {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  padding: 20px;
}
.canvasInner {
  position: relative;
  padding: 10px 0;
}
.title {
  width: 100%;
  margin-bottom: 0.5em;
  font-size: 2rem;
  text-align: center;
}
.filler {
  width: 100%;
  margin-bottom: 0.5em;
  font-size: 2rem;
  text-align: center;
  visibility: hidden;
}
.serif {
  font-family: "Roboto Serif", serif;
}
.dim {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.value {
  padding: 5px 10px;
  color: var(--gray-dark);
}
.download {
  width: auto;
  padding: 0;
  border: none;
  border-radius: 0;
}
.download .dim {
  display: none;
}
