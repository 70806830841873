.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0;
}
.controls {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}
.controls button {
  min-height: 48px;
}
.project {
  padding: 0;
  border-style: dashed;
  border-width: 1px;
  border-color: var(--gray-light);
}
.buffer {
  width: 100%;
  height: 40px;
}
.title {
  width: 100%;
  padding: 20px 0 0;
  text-align: center;
}
.canvas {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
