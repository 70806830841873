.outcome {
  border: 1px solid var(--gray-400);
  border-radius: 12px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  border-radius: 12px 12px 0 0;
  font-weight: 500;
  background: var(--gray-100);
}
.titleName {
  display: flex;
  align-items: center;
}
.remove {
  cursor: pointer;
  transition: all 0.2s ease-in;
}
.remove:hover {
  color: var(--brand-blue-400);
}
.index {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 1em;
  border-radius: 50%;
  color: var(--white);
  background-color: var(--gray-500);
  font-size: 0.75rem;
}
.controls {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.controls > * + * {
  margin-top: var(--flow-space, 1.1em);
}
.swatch {
  position: relative;
  top: 4px;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  cursor: pointer;
}
.color {
  width: 36px;
  height: 19px;
  border-radius: 2px;
}
.popover {
  position: absolute;
  z-index: 2;
  top: 42px;
  left: 0;
}
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.label {
  margin-bottom: 0.5em;
}
.iconList {
  display: flex;
  align-items: center;
}
.iconSvg {
  display: flex;
  align-items: center;
  width: 8px;
  margin-right: 0.5em;
}
.iconSvg svg {
  width: 100%;
  height: auto;
  fill: var(--gray-500);
}
.add {
  width: 100%;
  min-width: 325px;
  padding: 15px;
  background: none;
  border: 1px dashed var(--primary-main);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in;
}
.add:hover {
  background: var(--brand-blue-800);
}
.add span {
  font-size: 1.5rem;
  line-height: 1.63rem;
  color: var(--brand-blue-800);
}
.add:hover span {
  color: var(--white);
}
