.groups {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .groups {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
.group1 {
  margin: 20px auto;
}
.group2 {
  padding: 20px;
}
