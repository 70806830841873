.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  background-color: var(--primary-main);
}
.copy {
  margin: 0 10px;
  color: var(--white);
}
.logo {
  height: 36px;
  width: auto;
  margin: 0 10px;
}
