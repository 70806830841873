.settings {
  margin-top: 1em;
  border: 1px solid var(--gray-400);
  border-radius: 12px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  border-radius: 12px 12px 0 0;
  font-weight: 500;
  background: var(--gray-100);
  cursor: pointer;
}
.controls {
  display: flex;
  flex-direction: column;
  padding: 0 1em;
}
.closed .title {
  border-radius: 12px;
}
.closed .controls {
  display: none;
}
