.main {
  flex-grow: 1;
  width: 100%;
  margin: 30px 0 80px;
  background-color: var(--white);
}
.column {
  max-width: 1280px;
  margin: 0 auto;
}
.header {
  padding: 10px 20px;
}
.tabs {
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 20px;
  height: auto;
}
