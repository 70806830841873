.legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 0 0;
  min-width: 200px;
}
.legend div > * + * {
  margin-top: var(--flow-space, 0.2rem);
}
.serif {
  font-family: "Roboto Serif", serif;
}
.item {
  display: flex;
  align-items: center;
}
.icon {
  flex-shrink: 0;
  margin: 0 1rem 0 0;
}
.iconSvg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconSvg svg {
  height: 100%;
  width: auto;
}
