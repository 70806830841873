.group {
  position: relative;
  display: flex;
  justify-content: center;
}
.label {
  position: relative;
  flex-grow: 1;
}
.labelText {
  position: relative;
  height: 100%;
  width: 30px;
  margin-right: 45px;
  white-space: nowrap;
}
.rotate {
  transform: translate(-50%, -50%) rotate(270deg);
  white-space: nowrap;
  top: 50%;
  left: 50%;
  position: absolute;
  text-align: center;
}
.serif {
  font-family: "Roboto Serif", serif;
}
.array {
  display: grid;
  direction: rtl;
}
.mark-size-1000 > div {
  visibility: hidden;
}
.mark-size-1000 > div:nth-child(4n + 1) {
  visibility: visible;
}
.marks {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  text-align: right;
}
.mark {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
}
