.swatch {
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  cursor: pointer;
}
.color {
  width: 36px;
  height: 19px;
  border-radius: 2px;
}
.popover {
  position: absolute;
  z-index: 2;
  top: 42px;
  left: 0;
}
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.border {
  width: 100%;
  border-top: solid 2px var(--gray-light);
}
